import api from "../../api/api";


const getPortalDetails = (businessId) => {
    return new Promise((resolve, reject)=>{
        api.get("portal/"+businessId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const getCheckinDisplayList = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("token/checkin/display/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :"res.data.msg"
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}


const getNewsList = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/display-news").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :res.data.msg,
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}



const displayServices = {
    getCheckinDisplayList,
    getPortalDetails,
    getNewsList,
}

export default displayServices;