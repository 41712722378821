import api from "../../api/api";

const getListByUserId = (userId) =>{
    return new Promise((resolve, reject)=>{
        api.get("/business/list/"+userId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const save = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/business",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const update = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.put("/business",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const businessServices = {
    getListByUserId,
    save,
    update,
}

export default businessServices;