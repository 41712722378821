import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { ChartBackGroundColors } from "../../services/ChartColors.";


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
    responsive: true,
    //indexAxis: 'y',
    maintainAspectRatio: false,
    plugins: {
        legend: {
        position: "left",
        display: false
        },
    },
    scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
};








function QueueBarChart({dashboardData}) {

    let dataLabels = [];
    let datasets = [];
    let groupLabels = ["waiting","inProgress","closed","selfCancelled","notAppeared"];
    dashboardData.queueList.forEach((queue,i) => {
        dataLabels.push(queue.title);
    });


    groupLabels.forEach((label,i)=>{
        datasets.push({
            label:label,
            data: dashboardData.queueList.map(queue=>queue.report[label]),
            backgroundColor:ChartBackGroundColors[i],
        })
    })
    

    console.log(datasets);
    

    

    const data = {
        labels:dataLabels,
        datasets:datasets
        /*datasets: [
            {
                label: 'waiting',
                data: [30,3,3,1,4,0],
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
              {
                label: 'ip rogress',
                data: [0,0,0,0,0,0],
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
              },
              {
                label: 'closed',
                data: [9,1,4,1,7,0],
                backgroundColor: 'rgba(53, 62, 235, 0.5)',
              },
              {
                label: 'self cancelled',
                data: [0,0,0,0,0,0],
                backgroundColor: 'rgba(53, 53, 53, 0.5)',
              },
              {
                label: 'not appeared',
                data: [0,0,0,0,0,0],
                backgroundColor: 'rgba(53, 0, 0, 0.5)',
              },
        ],
        */
    };

    return (
      <>
          <Bar  options={options} data={data} />
      </>
    )
}

export default QueueBarChart