import React, { useState,useEffect } from 'react'
import addressServices from './addressServices';


import AddressForm from './AddressForm';
import { Alert, Snackbar } from '@mui/material';
import ConfirmDialog from '../../components/ConfirmDialog';
import { ContactMail, ContactPhone } from '@mui/icons-material';


export default function AddressComponent({userId,module,moduleId,titleLine = "Address Details", limit=3}) {
    const [addressList, setAddressList] = useState(null)
    const [loadingInProgress, setLoadingStatus] = useState(false);

    const [snackStatus, setSnackStatus] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackType, setSnackType] = useState("error");


    const getAddressList = () => {
        setLoadingStatus(true);
        addressServices.getList(userId,module,moduleId).then(res => {
            res.data.length > 0 ? setAddressList(res.data) : setAddressList(null);
            setLoadingStatus(false);
        }).catch(error=>{
            setLoadingStatus(false);
        })
    }


    useEffect(() => {
        getAddressList();
    }, [])


    const remove = (id)=>{
        addressServices.remove(id).then(res=>{
            getAddressList();
            setSnackStatus(true);
            setSnackType("success");
            setSnackMessage(res.data.msg);
        }).catch(error=>{
            setSnackStatus(true);
            setSnackMessage(error.msg);
            setSnackType("error");
        })
    }
    return (
      <>
        {!loadingInProgress &&
            <div className="card mt-2" >
                <Snackbar open={snackStatus} onClose={ ()=>{setSnackStatus(false)} } autoHideDuration={10000}  anchorOrigin={{ vertical: 'top', horizontal: 'center',  }}>
                    <Alert  severity={snackType} sx={{ width: '100%' }}> {snackMessage}</Alert>
                </Snackbar>
                <div className="card-header">{titleLine}</div>
                <div className="card-body">
                    <div className="row">
                        {addressList &&
                            <>
                                {addressList.map(address => {
                                                return (
                                                    <div className="col-md-6 p-2" key={address.id}>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="d-flex ">
                                                                    <span>
                                                                        {address.contactPerson}<br/>
                                                                        {address.addressLine1},{address.addressLine2}<br/>
                                                                        {address.city}, {address.state}, {address.zip}<br/>
                                                                        {address.contactPhone && address.contactPhone != null ? 
                                                                            <><ContactPhone/> {address.contactPhone}</> : "..."}<br/>
                                                                        {address.contactEmail && address.contactEmail != null ? 
                                                                            <><ContactMail/> {address.contactEmail}</> : "..."} 
                                                                        
                                                                    </span>
                                                                </div>
                                                                <div style={{position:"absolute",right:10,top:10}}>
                                                                    <AddressForm userId={userId} data={address} refreshList={getAddressList} module={module} moduleId={moduleId}/>
                                                                    <ConfirmDialog id={address.id} confirm={remove} >Are you Sure You want to remove this address</ConfirmDialog>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                }
                                {addressList.length < limit &&
                                    <div className="col-md-6 p-2">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="center"><h6><AddressForm userId={userId} module={module} moduleId={moduleId} refreshList={getAddressList}/> </h6></div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                        {!addressList &&
                            <div className="col-md-6 p-2">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="center"><h6><AddressForm userId={userId} module={module} moduleId={moduleId} refreshList={getAddressList}/> </h6></div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        }
      </>
    )
}