import React from 'react'
import { NavLink } from 'react-router-dom'
import { useParams } from 'react-router';

export default function QueueLayout({queueDetails,children}) {
 let { id } = useParams();
  return (

        <>
            <div className="container-fluid">
                <div className="row mt-2">
                    <div className="col-md-12">
                        
                            
                            

                                
                                
                                <div className="row">
                                    <div className="col-md-3 ">
                                        
                                        <div className="list-group rounded-0">
                                            <span className="list-group-item bg-dark text-white"><center> {queueDetails.title} </center></span>
                                            <NavLink className="list-group-item" aria-current="page" to={"/queue/business-hours/"+id}> Working Hours</NavLink>
                                            <NavLink className="list-group-item" aria-current="page" to={"/queue/business-breaks/"+id}> Break Timings</NavLink>
                                            <NavLink className="list-group-item" aria-current="page" to={"/queue/business-holiday/"+id}> Business Holidays</NavLink>
                                            <NavLink className="list-group-item" aria-current="page" to={"/queue/appointment-config/"+id}> Appointment Config</NavLink>
                                            {/* <NavLink className="list-group-item" aria-current="page" to={"/queue/checkin-config/"+id}> Checkin Config</NavLink> */ }
                                            <NavLink className="list-group-item" aria-current="page" to={"/queue/service-config/"+id}> Service</NavLink>
                                            <NavLink className="list-group-item" aria-current="page" to={"/queue/staff-config/"+id}> Counter Staff</NavLink>
                                            {/* <NavLink className="list-group-item" aria-current="page" to={"/queue/portal-config/"+id}> Portal</NavLink> */ }
                                        </div>
                                        <div className="card">
                                            <div className="card-body">
                                                <dl className="row">
                                                    <dt className="col-sm-6">Token Prifix</dt>
                                                    <dd className="col-sm-6">{queueDetails.prefix}</dd>
                                                    <dt className="col-sm-6">Stations</dt>
                                                    <dd className="col-sm-6">{queueDetails.stations}</dd>
                                                    <dt className="col-sm-6">Avg Processing Time</dt>
                                                    <dd className="col-sm-6">{queueDetails.avgProcessTime} Minuts</dd>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="">
                                            {children}
                                        </div>
                                    </div>

                                </div>
                            
                            
                    </div>
                    
                </div>
            </div>
        </>
    
    
  )
}
