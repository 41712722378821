import api from "../../api/api";



const getList = (id,module,moduleId) =>{
    return new Promise((resolve, reject)=>{
        api.get("address/list/"+id+"/"+module+"/"+moduleId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const save = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("address",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}


const remove = (id) => {
    return new Promise((resolve, reject)=>{
        api.delete("address/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const addressServices = {
    getList,
    save,
    remove,
}

export default addressServices;