import React from 'react'
import { Route, Routes } from 'react-router'
import Login from '../auth/pages/Login'
import Logout from '../auth/pages/Logout'
import Register from '../auth/pages/Register'
import Profile from '../auth/pages/Profile'
import Dashboard from '../dashboard/pages/Dashboard'
import UserListPage from '../users/pages/UserListPage'
import UserRolePage from '../users/pages/UserRolePage'
import NotificationComponent from '../modules/Notification/NotificationComponent'
import MasterListPage from '../master/pages/MasterListPage'

import BusinessListPage from '../modules/Business/BusinessListPage'
import BusinessHoursPage from '../app/Queue/pages/BusinessHoursPage'
import BusinessHolidayPage from '../app/Queue/pages/BusinessHolidayPage'
import AppointmentConfigPage from '../app/Queue/pages/AppointmentConfigPage'
import CheckInConfigPage from '../app/Queue/pages/CheckInConfigPage'
import PortalConfigPage from '../app/Queue/pages/PortalConfigPage'
import ServicesPage from '../app/Queue/pages/ServicesPage'
import StaffPage from '../app/Queue/pages/StaffPage'
import QueueListPage from '../app/Queue/pages/QueueListPage'
import BusinessBreaksPage from '../app/Queue/pages/BusinessBreaksPage'
import AppointmentListPage from '../app/Appointments/pages/AppointmentListPage'
import CheckinQueue from '../app/CheckinQueue/pages/CheckinQueue'
import DiasplayPage from '../app/Display/pages/DiasplayPage'
import KioskPortalPage from '../app/Kiosk/pages/KioskPortalPage'
import KioskQueuePage from '../app/Kiosk/pages/KioskQueuePage'
import KioskCheckinFormPage from '../app/Kiosk/pages/KioskCheckinFormPage'
import KioskTokenDetailsPage from '../app/Kiosk/pages/KioskTokenDetailsPage'
import NewsListPage from '../app/News/pages/NewsListPage'
import MiniDisplayPage from '../app/Display/pages/MiniDisplayPage'
import ReportPage from '../app/Report/pages/ReportPage'





export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={ <Dashboard/> } />
      <Route path="/auth/login" element={ <Login/> } />
      <Route path="/auth/register" element={ <Register/> } />
      <Route path="/auth/profile" element={ <Profile/> } />
      <Route path="/auth/logout" element={ <Logout/> } />
      
      <Route path="/notification/list" element={ <NotificationComponent /> } />
      <Route path="/master/list" element={ <MasterListPage /> } />

      <Route path="/business/details" element={ <BusinessListPage /> } />

      <Route path="/user/list" element={ <UserListPage/> } />
      <Route path="/user/role" element={ <UserRolePage/> } />

      <Route path="/news/list" element={ <NewsListPage /> } />

      { /* Kiosk Work Start */ }
      <Route path="/kiosk/portal/:businessId" element={ <KioskPortalPage/>  } />
      <Route path="/kiosk/queue/:queueId" element={ <KioskQueuePage/>  } />
      <Route path="/kiosk/checkin/:businessId/:queueId/:serviceId" element={ <KioskCheckinFormPage/> } />
      <Route path="/kiosk/token-details" element={ <KioskTokenDetailsPage/> } />
      { /* Kiosk Work End*/ } 



      { /* Queue List Work Start */ }
      <Route path="/checkin/queue" element={ <CheckinQueue />  } />
      <Route path="/checkin/display" element={ <DiasplayPage/>  } />
      <Route path="/checkin/mini-display" element={ <MiniDisplayPage/>  } />
      <Route path="/appointment/queue" element={ <AppointmentListPage/>  } />
      { /* Queue List Work End*/ } 
      

      { /* Queue and Queue Config Work */ }
      <Route path="/queue/list" element={ <QueueListPage />  } />
      <Route path="/queue/business-hours/:id" element={ <BusinessHoursPage /> } />
      <Route path="/queue/business-breaks/:id" element={ <BusinessBreaksPage /> } />
      <Route path="/queue/business-holiday/:id" element={ <BusinessHolidayPage /> } />
      <Route path="/queue/appointment-config/:id" element={ <AppointmentConfigPage /> } />
      <Route path="/queue/checkin-config/:id" element={ <CheckInConfigPage /> } />
      <Route path="/queue/portal-config/:id" element={ <PortalConfigPage /> } />
      <Route path="/queue/service-config/:id" element={ <ServicesPage /> } />
      <Route path="/queue/staff-config/:id" element={ <StaffPage /> } />
      

      <Route path="/report" element={ <ReportPage/>  } />
    

    </Routes>
  )
}
