import { Alert, Snackbar, Switch } from '@mui/material';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router';


import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';

import { Edit } from '@mui/icons-material';


import CommonLoadingSkeleton from '../../../components/CommonLoadingSkeleton';
import ExpandableCard from '../../../components/ExpandableCard'
import AppLayout from '../../../layout/components/AppLayout'
import QueueLayout from '../components/QueueLayout'
import queueServices from '../queueServices';
import queueUpdateServices from '../queueUpdateServices';
import ConfirmDialog from '../../../components/ConfirmDialog';



const HolidayForm = ({queueId,businessId, alertHandler, refreshList, data=null})=>{

    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);



    const initialValues = {
        queueId: queueId,
        businessId:businessId,
        occasion : "",
        holidayDate : dayjs()
    }

    const onSubmit = (values, onSubmitProps) => {
        queueUpdateServices.updateHolidays(values).then(res => {
            refreshList();
            alertHandler(res.msg);
            setDialogStatus(false);
        }).catch(error => {
            alertHandler(error.msg,"error");
        })
    }

    const validationRule = Yup.object({
        occasion:Yup.string().required("Required"),
        holidayDate:Yup.string().required("Required"),
    });


    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const updateData = ()=>{
        let autoFill = {
            id:data.id,
            businessId:businessId,
            occasion : data.occasion,
            holidayDate : data.holidayDate
        }
        setFormValues(autoFill);
        
        setDialogStatus(true)
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }

    return (
        <>
            {!data && <button className="btn btn-primary" onClick={addNew}> Add Holiday</button>}
            {data &&
              <Tooltip title="Update">
                  <IconButton onClick={updateData} style={{padding:"0px"}}>
                      <Edit/>
                  </IconButton>
              </Tooltip>
            }
          
  
              <Dialog
                  open={dialogStatus}
                  keepMounted
                  onClose={handleClose}
                  fullWidth={true}
                  maxWidth="md"
                  aria-describedby="alert-dialog-slide-description"
              >
  
                  <Formik
                      initialValues={formValues || initialValues}
                      validationSchema={validationRule}
                      onSubmit={onSubmit}
                      enableReinitialize validateOnMount
                  >
                      {
                          (formik) => {
                              //console.log(formik.values);
                              return (
                                  <Form>
                                      <DialogTitle>{"Holiday"}</DialogTitle>
                                      <DialogContent>
                                          <div className="row p-2">
                                              
                                              <div className="col-md-12">
  
                                                  <div className="row">
                                                      
  
                                                      <FormikControl control="input" formik={formik} type="text" 
                                                          label="Occasion" name="occasion" 
                                                          className="col-md-4 col-xs-12 p-2" 
                                                      />

                                                        <FormikControl control="date" formik={formik} type="date" 
                                                            label="Holiday Date" name="holidayDate" 
                                                            className="col-md-4 col-xs-12 p-2" 
                                                        />
  
                                                      
  
                                                  </div>
  
                                              </div>
                                          </div>
  
                                          <div className="row">
                                          { /*  JSON.stringify(formik.values) */}
                                          </div>
  
                                          
                                      </DialogContent>
                                      <DialogActions>
                                          
                                          {savingInProgress && <div className="row"><LinearProgress/></div> }
                                          {!savingInProgress && 
                                              <>
                                              <button className="btn btn-success" type="submit">Save</button>
                                              <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                              </>
                                          }
                                          
                                      </DialogActions>
                                  </Form>
                              )
                          }
                      }
                  </Formik>
              </Dialog>
        </>
      )
}



export default function BusinessHolidayPage() {

    let { id } = useParams();

    const [loading,setLoadingStatus] = useState(false);
    const [queueConfig,setQueueConfig] = useState(null);

    const [snackStatus, setSnackStatus] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackType, setSnackType] = useState("error");

    const getQueueConfig = useCallback(()=>{
        setLoadingStatus(true);
        queueServices.getQueueConfig(id).then(res=>{
            setQueueConfig(res.data);
        }).catch(error=>{
            setSnackStatus(true);
            setSnackType("error");
            setSnackMessage(error.msg);
        }).finally(()=>{
            setLoadingStatus(false);
        })

    },[id]);

    const alertHandler = (message,messageType="success") =>{
        setSnackStatus(true);
        setSnackType(messageType);
        setSnackMessage(message);
    }

    const remove = (id)=> {
        queueUpdateServices.removeHoliday(id).then(res=>{
            getQueueConfig();
            alertHandler(res.msg)
        }).catch(error =>{
            alertHandler(error.msg,"error")
        })
    }


    useEffect(()=>{
        getQueueConfig();
    },[getQueueConfig])
    

    return (
        <AppLayout>
            <Snackbar open={snackStatus} onClose={ ()=>{setSnackStatus(false)} } autoHideDuration={10000}  anchorOrigin={{ vertical: 'top', horizontal: 'center',  }}>
                <Alert  severity={snackType} sx={{ width: '100%' }}> {snackMessage}</Alert>
            </Snackbar>
            {loading && <CommonLoadingSkeleton/> }
            {queueConfig && queueConfig.businessHolidays &&
                <QueueLayout queueDetails={queueConfig.queueDetails}>
                    <ExpandableCard title={"Business Holidays"} defaultOpen={true}>
                        <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <div className="card">
                                            <div className="card-body">
                                                <HolidayForm queueId={id} businessId={queueConfig.queueDetails.businessId} alertHandler={alertHandler} refreshList={getQueueConfig} />
                                            </div>
                                        </div>
                                    </div>
                                    {queueConfig.businessHolidays.map(businessHoliday=>{
                                        return(
                                            
                                                <div className="col-md-4 mb-3" key={businessHoliday.id}>
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-md-8">
                                                                    <p><strong> "{businessHoliday.occasion}" </strong> on <strong>{businessHoliday.holidayDate}</strong> </p> 
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <HolidayForm queueId={id} businessId={queueConfig.queueDetails.businessId} alertHandler={alertHandler} refreshList={getQueueConfig} data={businessHoliday} />
                                                                    <ConfirmDialog id={businessHoliday.id} confirm={remove} >Are you Sure You want to remove</ConfirmDialog>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                        )
                                    })}
                                </div>
                        </div>
                    </ExpandableCard>
            
                </QueueLayout>
            }
      </AppLayout>
    )
}

