import { Add, Grid4x4, PeopleAlt, Print, SupportAgent, VoiceChat } from '@mui/icons-material';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import RealTimeReport from '../components/RealTimeReport'

import AppLayout from '../../layout/components/AppLayout';



export default function Dashboard() {

    const dashboardData = false;
    const authUser = useSelector((state) => state.auth);
    const navigate = useNavigate(); 

    if(authUser.role === 5){
        navigate("/kiosk/portal/1");
    }
    

  return(
    
      <AppLayout>
        <>
            <div className="container-fluid">
                <RealTimeReport />
            </div>
        </>
    </AppLayout>

    
 )
}



