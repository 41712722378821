import { Alert, CircularProgress, Snackbar, Switch } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import CommonLoadingSkeleton from '../../../components/CommonLoadingSkeleton';
import ExpandableCard from '../../../components/ExpandableCard'
import AppLayout from '../../../layout/components/AppLayout'
import QueueLayout from '../components/QueueLayout'
import queueServices from '../queueServices';



import dayjs from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextField } from '@mui/material';
import queueUpdateServices from '../queueUpdateServices';




const BusinessDay = ({businessDay,alertHandler}) =>{
    
    const [dayStart,setDayStart] = useState(dayjs('2022-11-22T'+businessDay.dayStart));
    const [dayEnd,setDayEnd] = useState(dayjs('2022-11-22T'+businessDay.dayEnd));
    const [dayStatus,setDayStatus] = useState(businessDay.dayStatus?true:false);
    const [touched,setTouched] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);


    /*
        let yourDate = new Date();
        yourDate = yourDate.toISOString().split('T')[0];
        const [dayStart,setDayStart] = useState(dayjs(yourDate+businessDay.dayStart));
    */
    const getWeekDayName = (dayNo) =>{
        let weekDays = ["","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"]
        return weekDays[dayNo];
    }

    const handleDayStartChange = (newValue) =>{
        console.log(newValue.format("HH:mm:ss"));
        setDayStart(newValue);
        setTouched(true)
    }

    const handleDayEndChange = (newValue) =>{
        console.log(newValue.format("HH:mm:ss"));
        setDayEnd(newValue);
        setTouched(true)
    }

    const handleDayStatusChange = (event) =>{
        setDayStatus(event.target.checked);
        setTouched(true)
    }

    
    
    const update = ()=>{
        setSavingStatus(true);
        let payload = {
            id:businessDay.id,
            dayStart:dayStart.format("HH:mm:ss"),
            dayEnd:dayEnd.format("HH:mm:ss"),
            dayStatus
        }
        queueUpdateServices.updateBusinessHours(payload).then(res=>{
            alertHandler(res.msg);
            setTouched(false);
        }).catch(error=>{
            alertHandler(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    return (
        <tr>
            <th>{getWeekDayName(businessDay.dayNo)}</th>
            <th><Switch checked={dayStatus} onChange={handleDayStatusChange} name="Day Status" /></th>
            <td>
                <TimePicker
                    label="Time"
                    value={dayStart}
                    onChange={handleDayStartChange}
                    renderInput={(params) => <TextField {...params} variant="standard" />}
                />
            </td>
            <td>
                <TimePicker
                    label="Time"
                    value={dayEnd}
                    onChange={handleDayEndChange}
                    renderInput={(params) => <TextField {...params} variant="standard" />}
                />
            </td>
            <td>
                {touched && !savingInProgress && <button type="button" className="btn btn-success btn-sm" onClick={update}>Save</button>}
                {savingInProgress &&  <CircularProgress/> }
            </td>
            
        </tr>
    )
}


export default function BusinessHoursPage() {
    
    let { id } = useParams();

    const [loading,setLoadingStatus] = useState(false);
    const [queueConfig,setQueueConfig] = useState(null);

    const [snackStatus, setSnackStatus] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackType, setSnackType] = useState("error");

    const getQueueConfig = useCallback(()=>{
        setLoadingStatus(true);
        queueServices.getQueueConfig(id).then(res=>{
            setQueueConfig(res.data);
        }).catch(error=>{
            setSnackStatus(true);
            setSnackType("error");
            setSnackMessage(error.msg);
        }).finally(()=>{
            setLoadingStatus(false);
        })

    },[id]);


    const alertHandler = (message,messageType="success") =>{
        setSnackStatus(true);
        setSnackType(messageType);
        setSnackMessage(message);
    }


    useEffect(()=>{
        getQueueConfig();
    },[getQueueConfig])
    
    return (
        <AppLayout>
            <Snackbar open={snackStatus} onClose={ ()=>{setSnackStatus(false)} } autoHideDuration={10000}  anchorOrigin={{ vertical: 'top', horizontal: 'center',  }}>
                <Alert  severity={snackType} sx={{ width: '100%' }}> {snackMessage}</Alert>
            </Snackbar>

            
                {loading && <CommonLoadingSkeleton/> }
                {queueConfig && queueConfig.businessHours.length > 0 &&
                    <QueueLayout queueDetails={queueConfig.queueDetails}>
                        <ExpandableCard title={"Business Hours"} defaultOpen={true}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12 table-responsive">
                                        <p className="text-primary small">TimeZone: Asia/Calcutta</p>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th>Open</th>
                                                    <th>Close</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    
                                                    queueConfig.businessHours.map(businessDay=>{
                                                        return(
                                                            <BusinessDay businessDay={businessDay} key={businessDay.id} alertHandler={alertHandler}/>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </div>
                        </ExpandableCard>
                    </QueueLayout>
                }
            
        </AppLayout>
        )
    }
