import React, { useEffect, useCallback, useRef, useState } from 'react'
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout'
import FilterComponent from '../components/FilterComponent';



import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import moment from 'moment';
import MasterServices from '../../../services/masterServices';
import userServices from '../../../users/userServices';
import { Download } from '@mui/icons-material';


function ReportPage({AppAlert}) {

    const [tokentList,setTokenList] =  useState(null);


    /* Prepair User Master start*/
    const [masters,setMasters] = useState(null);
    const getUserDD = () => {
        userServices.getUserDD().then(res=>{
            setMasters({"users":res.data})
        })
    }

    const getUserName = (type, id) => {
        let label = masters[type].find(m => m.value === id)
        if(label!== undefined){
            return label.firstName+""+label.lastName;
        } else {
            return "NA";
        }
        
    }

    const getWorkLocation = (type, id) => {
        let label = masters[type].find(m => m.value === id)
        if(label!== undefined){
            return label.workLocation;
        } else {
            return "NA";
        }
    }

    useEffect(()=>{
        getUserDD();
    },[]);

    /* Prepair User Master end*/

    /*   grid Work */
    
    const gridRef = useRef();
    const [defaultColDef] = useState({
        floatingFilter: true,
        filterParams: {
          buttons: ['apply'],
        },
        resizable: true,
        width:120,
        filter:true,
        sortable: true,
    })
    const [columnDefs,setColumnDefs] = useState([]);
    const setupColDef = () =>{
        setColumnDefs([
            {field:"id",headerName:"Token Sr. No"},
            {field:"checkinId",headerName:"Day V No"},
            {field:"tokenNo",headerName:"Token Number"},
            {field:"checkInDate",headerName:"Date"},
            {field:"queueTitle",headerName:"Queue"},
            {field:"serviceTitle",headerName:"Service"},

            {field:"customerFirstName",headerName:"Customer Name"},
            {field:"counterUserId",headerName:"Counter Person",valueGetter: params => getUserName("users",params.data.counterUserId)},
            {field:"counterUserId",headerName:"Counter #",valueGetter: params => getWorkLocation("users",params.data.counterUserId)},

            {field:"statusTitle",headerName:"Status"},
            
            
            
            

        ]);
    }

    const rowClassRules = {
        'bg-primary text-white' : params => params.data.status === 1,
        'bg-warning' : params => params.data.status === 2,
        'bg-info': params => params.data.status === 3,
        //'bg-success': params => params.data.status === 4,
        'bg-danger': params => params.data.status === 5,
        'bg-dark text-white': params => params.data.status === 6,
        
        
        
        
    };
    
    
    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv("data.csv");
    }, []);

    /* Grid Work */


    return (
        <AppLayout>
            <div className="container-fluid">
                <FilterComponent setTokenList={setTokenList} AppAlert={AppAlert} setupColDef={setupColDef} />
                {tokentList !== null &&
                <div className="row">
                        <div className="col-md-12 mb-2">
                            <button type="button" onClick={onBtnExport} className="btn btn-primary float-end"><Download/>  Export</button>
                        </div>
                        <div className="col-md-12">
                        <div className="ag-theme-alpine" style={{height: 450, width:"auto"}}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={tokentList}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    rowClassRules={rowClassRules}
                                    pagination={true}
                                    animateRows={true}
                                    >
                                </AgGridReact>
                            </div>
                        </div>
                    </div>
                }
                
            </div>
        </AppLayout>
    )
}

export default withAlert(ReportPage)