import api from "../../api/api";



const getAppointmentList = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("token/appointment/list/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :"res.data.msg"
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}


const updateAppointmentStatus = (data) =>{
    return new Promise((resolve, reject)=>{
        api.post("token/appointment/update-status",data).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg :"res.data.msg"
                })
            }
          }).catch( (error) => {
            reject({
                msg : "Something Went Wrog",
                error : error
            })
          })
    })
}


const appointmentServices = {
    getAppointmentList,
    updateAppointmentStatus,
}

export default appointmentServices;