import { Announcement } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react'

import { useSpeechSynthesis } from 'react-speech-kit';

function AppAnnouncement({displayList,getCounterNumber,announceTokenId, voiceIndex}) {
    const { speak,voices } = useSpeechSynthesis();
    const [announcementList, setAnnouncementList]  = useState([]);



    const prefixLang =(prefix) =>{
        return prefix.split('').join(', ');
    }

    useEffect(()=>{
        //console.log("in announcement");
        if(displayList ){
            let newAnnpuncementList = []
            //console.log("here in announcemet ",announceTokenId);
            displayList.forEach(token=>{
                if(token.status === 2 && token.id === announceTokenId) {
                    
                    //let newAnnouncement = "Token Number "+prefixLang(token.tokenPrefix)+"! "+token.tokenNo+"! Counter Number "+getCounterNumber("users",token.counterUserId)+"say sammppark karey";
                    //let newAnnouncement = "Token Number "+prefixLang(token.tokenPrefix)+"! "+token.firstName+"! Counter Number "+getCounterNumber("users",token.counterUserId)+"say sammppark karey";
                    //let newAnnouncement = "Token Number "+prefixLang(token.tokenPrefix)+"! "+token.tokenNo+"! Counter Number "+getCounterNumber("users",token.counterUserId);
                    let newAnnouncement = "Token Number "+prefixLang(token.tokenPrefix)+"! "+token.tokenNo+"! Counter Number "+getCounterNumber("users",token.counterUserId) + " से संपर्क करे";
                    //let newAnnouncement = "Token Number "+prefixLang(token.tokenPrefix)+"! "+token.tokenNo+"! काउंटर नंबर "+getCounterNumber("users",token.counterUserId);
                    console.log(newAnnouncement);
                    newAnnpuncementList.push(newAnnouncement);
                }
            })
            setAnnouncementList(newAnnpuncementList);
        }
        

    },[displayList])


    const makeAnnouncement = () =>{
        announcementList.forEach(announcement => {
            speak({ text: announcement,voice:voices[voiceIndex] });
        });
    }


    useEffect(() => {
        setTimeout(() => {
          ref.current.click();
        }, 50); //miliseconds
      }, [announcementList]);
    const ref = useRef(null);






    

    return (
        <>
        {/*JSON.stringify(announcementList)*/}
        <button ref={ref} type="button" className="d-none" onClick={makeAnnouncement} ></button>
        {/*<h2>voice index is{voiceIndex} </h2>*/}
        {/*
        
        <button
                type="button"
                onClick={() => speak({ text:"Token Number M,E,G,27 counter no 8 से संपर्क करे",voice:voices[7] })}
              >
                Speak
        </button>
        */}
        </>

        
    )
}

export default AppAnnouncement