import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Skeleton, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../components/FormikControl/FormikControl';

import { Edit, VerifiedSharp } from '@mui/icons-material';
import { Alert, Snackbar } from '@mui/material'
import documentServices from './documentServices';

export default function DocumentForm({userId,refreshList,masters, module,moduleId, data=null}) {

    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);


    const [snackStatus, setSnackStatus] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackType, setSnackType] = useState("error");


    const initialValues = {
        userId: userId,
        module:module,
        moduleId:moduleId,
        fileName: "",
        originalName:"",
        documentType:"",
    }

    const onSubmit = (values, onSubmitProps) => {
        console.log(values);
        //setSavingStatus(true)
        documentServices.save(values).then(res =>{
            setSavingStatus(false);
            console.log(res);
            if(res.error){
                setSnackStatus(true);
                setSnackType("error");
                setSnackMessage(res.msg);
            } else {
                refreshList();
                setSnackStatus(true);
                setSnackType("success");
                setSnackMessage(res.msg);
                setDialogStatus(false);
            }
        })
    }

    const validationRule = Yup.object({
        documentType: Yup.string().required("Required"),
    });

    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const updateData = ()=>{

        let autoFill = {
            id:data.id,
            userId: data.userId,
            module:module,
            moduleId:moduleId,
            fileName:data.fileName,
            originalName:data.originalName,
            documentType: data.documentType,
        }
        setFormValues(autoFill);
        
        setDialogStatus(true)
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }


    return (
      <>
          {!data && <button className="btn btn-primary btn-sm" onClick={addNew}> Add Document</button>
          
          
          
          }
          {data && 
            <>
            {data.verified == 0 ?  
            
                <Tooltip title="Update Document">
                    <IconButton onClick={updateData} style={{padding:"0px"}}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
            :  
                <VerifiedSharp className="float-end text-success me-2" />}
            </>
         }

        
            <Snackbar open={snackStatus} onClose={ ()=>{setSnackStatus(false)} } autoHideDuration={10000}  anchorOrigin={{ vertical: 'top', horizontal: 'center',  }}>
                <Alert  severity={snackType} sx={{ width: '100%' }}> {snackMessage}</Alert>
            </Snackbar>

            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >

                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Add Document"}</DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">
                                            
                                            <div className="col-md-12">

                                                <div className="row">
                                                    
                                                    <FormikControl control="file" formik={formik} 
                                                        label="Document" name="fileName" 
                                                        className="col-sm-12 col-xs-12 p-2" 
                                                    />

                                                    {masters && 
                                                        <FormikControl control="autoselect" formik={formik} 
                                                            label="Document Type" name="documentType"  
                                                            options={masters['document-type']} multiple={false} 
                                                            className="col-sm-12 col-xs-6 p-2"
                                                        />
                                                    }

                                                    <FormikControl control="input" formik={formik} 
                                                        label="Document Name" name="originalName" 
                                                        className="col-sm-12 col-xs-12 p-2" 
                                                    />

                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                        {   JSON.stringify(formik.values)}
                                        </div>

                                        {savingInProgress && <div className="row"><LinearProgress/></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                        
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
      </>
    )
}