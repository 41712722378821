import React, { useEffect, useState } from 'react'
import appointmentServices from '../appointmentServices';


export default function UpdateStatusComponent  ({appointmentId, appointmentTimeStamp, refreshList, status}) {

    const [timeDiffrence ,setTimeDiffrence] = useState(null);

    setInterval(()=>{
        let currentTimeStamp = Math.floor(Date.now() / 1000);
        let diff = appointmentTimeStamp - currentTimeStamp; 
        setTimeDiffrence(diff);
    }, 1000);


    const updateStatus = (id,status) =>{
        let data = {
            id:id,
            status:status
        }
        //console.log(data);
        appointmentServices.updateStatus(data).then(res=>{
            console.log(res);
            refreshList();
        }).catch(error=>{
            console.log(error.msg);
        })
    }
    return (
        <>
            {status === 1 &&
                <>
                    {timeDiffrence < 0 && timeDiffrence > -21600 &&  <button type="button" className="btn btn-start" onClick={()=>updateStatus(appointmentId,2)}> Start  </button> }
                    {timeDiffrence < 0 && <button type="button" className="btn btn-success" onClick={()=>updateStatus(appointmentId,2)}> Start  </button> }
                    {timeDiffrence < -21600 && <button type="button" className="btn btn-danger" onClick={()=>updateStatus(appointmentId,3)}> Cancel </button> }
                </>
            }

            {status === 2 &&
                <button type="button" className="btn btn-warning" onClick={()=>updateStatus(appointmentId,4)} > Finish  </button>
            }
            
        </>
    )
}