import React, { useState,useEffect, useCallback } from 'react'
import contactServices from './contactServices';
import ContactForm from './ContactForm';
import { Alert, Snackbar } from '@mui/material';
import ConfirmDialog from '../../components/ConfirmDialog';
import { ContactMail, ContactPhone } from '@mui/icons-material';


export default function ContactComponent({userId,module,moduleId,titleLine = "Contact Details", limit=3}) {
    const [contactList, setContactList] = useState(null)
    const [loadingInProgress, setLoadingStatus] = useState(false);

    const [snackStatus, setSnackStatus] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackType, setSnackType] = useState("error");


    const getContactList =  useCallback(() => {
        setLoadingStatus(true);
        contactServices.getList(userId,module,moduleId).then(res => {
            res.data.length > 0 ? setContactList(res.data) : setContactList(null);
            setLoadingStatus(false);
        })
    },[userId,module,moduleId]);


    useEffect(() => {
        getContactList();
    }, [getContactList])

    const remove = (id)=>{
        contactServices.remove(id).then(res=>{
            getContactList();
            setSnackStatus(true);
            setSnackType("success");
            setSnackMessage(res.data.msg);
        }).catch(error=>{
            setSnackStatus(true);
            setSnackMessage(error.msg);
            setSnackType("error");
        })
    }
    return (
      <>
        {!loadingInProgress &&
            <div className="card mt-2" >
                <Snackbar open={snackStatus} onClose={ ()=>{setSnackStatus(false)} } autoHideDuration={10000}  anchorOrigin={{ vertical: 'top', horizontal: 'center',  }}>
                    <Alert  severity={snackType} sx={{ width: '100%' }}> {snackMessage}</Alert>
                </Snackbar>
                <div className="card-header">{titleLine}</div>
                <div className="card-body">
                    <div className="row">
                        {contactList &&
                            <>
                                {contactList.map(contact => {
                                                return (
                                                    <div className="col-md-6 p-2" key={contact.id}>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="d-flex ">
                                                                    <span>
                                                                        {contact.contactPerson}<br/>
                                                                        {contact.contactPhone && contact.contactPhone != null ? 
                                                                            <><ContactPhone/> {contact.contactPhone}</> : "..."}<br/>
                                                                        {contact.contactEmail && contact.contactEmail != null ? 
                                                                            <><ContactMail/> {contact.contactEmail}</> : "..."} 
                                                                        
                                                                    </span>
                                                                </div>
                                                                <div style={{position:"absolute",right:10,top:10}}>
                                                                    <ContactForm userId={userId} data={contact} refreshList={getContactList} module={module} moduleId={moduleId}/>
                                                                    <ConfirmDialog id={contact.id} confirm={remove} >Are you Sure You want to remove this contact</ConfirmDialog>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                }
                                {contactList.length < limit &&
                                    <div className="col-md-6 p-2">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="center"><h6><ContactForm userId={userId} module={module} moduleId={moduleId} refreshList={getContactList}/> </h6></div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                        {!contactList &&
                            <div className="col-md-6 p-2">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="center"><h6><ContactForm userId={userId} module={module} moduleId={moduleId} refreshList={getContactList}/> </h6></div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        }
      </>
    )
}