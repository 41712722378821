import api from "../api/api"

const saveData = (payload) =>{
    return new Promise((resolve, reject)=>{
        let data = {...payload};
        delete data.masterName;
        //console.log("here ");
        
        api.post("masters/data/"+payload.masterName,data).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
        
    })
}


const removeMaster = (payload) => {
    return new Promise((resolve, reject)=>{
        api.delete("masters/data/"+payload.masterName+"/"+payload.id).then( (res)=>{
            if(!res.data.error){
                resolve(res);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const adminMasterServices = {
    saveData,
    removeMaster,
}

export default adminMasterServices;