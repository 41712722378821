import React, { useState,useEffect } from 'react'
import documentServices from './documentServices';

import DocumentForm from './DocumentForm';
import MasterServices from '../../services/masterServices';
import { Alert, Snackbar } from '@mui/material';
import ConfirmDialog from '../../components/ConfirmDialog';

export default function DocumentComponent({userId,module,moduleId,titleLine = "Documents",limit=5}) {
    const [documentList, setDocumentList] = useState(null)
    const [loadingInProgress, setLoadingStatus] = useState(false);
    const [masters,setMasters] = useState(null);

    const [snackStatus, setSnackStatus] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackType, setSnackType] = useState("error");

    const getDocumentList = () => {
        setLoadingStatus(true);
        documentServices.getList(userId,module,moduleId).then(res => {
            res.data.length > 0 ? setDocumentList(res.data) : setDocumentList(null);
            setLoadingStatus(false);
        })
    }


    const getMasterList = () => {
        MasterServices.get(["document-type","job-type"]).then(res=>{
            setMasters(res);
            getDocumentList();
        })
        
    }

    const getMasterLabel = (type, id) => {
        let label = masters[type].find(m => m.value == id)
        return label.label;
    }


    useEffect(() => {
        getMasterList();
    }, [])


    const remove = (id)=>{
        documentServices.remove(id).then(res=>{
            getDocumentList();
            setSnackStatus(true);
            setSnackType("success");
            setSnackMessage(res.data.msg);
        }).catch(error=>{
            setSnackStatus(true);
            setSnackMessage(error.msg);
            setSnackType("error");
        })
    }
    return (
      <>
        {!loadingInProgress &&
            <div className="card mt-2" id="document">
                <Snackbar open={snackStatus} onClose={ ()=>{setSnackStatus(false)} } autoHideDuration={10000}  anchorOrigin={{ vertical: 'top', horizontal: 'center',  }}>
                    <Alert  severity={snackType} sx={{ width: '100%' }}> {snackMessage}</Alert>
                </Snackbar>
                <div className="card-header ">{titleLine}
                <span className="float-end">
                    <DocumentForm userId={userId} masters={masters} refreshList={getDocumentList} module={module} moduleId={moduleId} />
                </span>
                </div>
                <div className="card-body">
                    <div className="row">
                        <>
                            {documentList &&
                                <>
                                    {
                                        documentList.map(document => {
                                            return (
                                                <div className="col-md-4" key={document.id}>
                                                    <div className="card mb-2">
                                                        <div className="card-header ">{ masters && getMasterLabel("document-type",document.documentType) }
                                                            <span className="float-end">
                                                                <DocumentForm userId={userId} data={document} refreshList={getDocumentList} masters={masters} module={module} moduleId={moduleId}/>
                                                                <ConfirmDialog id={document.id} confirm={remove} >Are you Sure You want to remove this document</ConfirmDialog>
                                                            </span>
                                                        </div>
                                                        <div className="card-body"> <a href={ process.env.REACT_APP_BASE_URL+"/uploads/documents/"+document.fileName} target="_blank"> {document.fileName}</a></div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {documentList.length < limit &&
                                        <div className="col-md-4 p-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="center"><h6><DocumentForm userId={userId} refreshList={getDocumentList} masters={masters} module={module} moduleId={moduleId} /> </h6></div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                            {!documentList &&
                                <div className="col-md-6 p-2">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="center"><h6><DocumentForm userId={userId} refreshList={getDocumentList} masters={masters} module={module} moduleId={moduleId} /> </h6></div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    </div>
                </div>
            </div>
        }
      </>
    )
}