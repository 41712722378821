import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../components/FormikControl/FormikControl';

import { Edit } from '@mui/icons-material';
import { Alert, Snackbar } from '@mui/material'
import addressServices from './addressServices';


export default function AddressForm({userId,refreshList,module,moduleId, data=null}) {

    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);


    const [snackStatus, setSnackStatus] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackType, setSnackType] = useState("error");


    const initialValues = {
        userId: userId,
        module:module,
        moduleId:moduleId,
        country:"India",
        contactPerson:"",
        contactPhone:"",
        contactEmail:"",
        state: "",
        city:"",
        zip:"",
        addressLine1:"",
        addressLine2:"",
    }

    const onSubmit = (values, onSubmitProps) => {
        console.log(values);
        //setSavingStatus(true)
        addressServices.save(values).then(res =>{
            setSavingStatus(false);
            console.log(res);
            if(res.error){
                setSnackStatus(true);
                setSnackType("error");
                setSnackMessage(res.msg);
            } else {
                refreshList();
                setSnackStatus(true);
                setSnackType("success");
                setSnackMessage(res.msg);
                setDialogStatus(false);
            }
        })
    }

    const validationRule = Yup.object({
        state: Yup.string().required("Required"),
        contactPerson:Yup.string().required("Required"),
        contactPhone:Yup.string().required("Required"),
        contactEmail:Yup.string().required("Required"),
        city:Yup.string().required("Required"),
        zip:Yup.string().required("Required"),
        addressLine1:Yup.string().required("Required"),
        addressLine2:Yup.string().required("Required"),
        
    });

    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const updateData = ()=>{

        let autoFill = {
            id:data.id,
            userId: data.userId,
            module:module,
            moduleId:moduleId,
            country: data.country,
            contactPerson: data.contactPerson,
            contactPhone: data.contactPhone,
            contactEmail: data.contactEmail,
            state: data.state,
            city:data.city,
            zip:data.zip,
            addressLine1:data.addressLine1,
            addressLine2:data.addressLine2,
            
        }
        setFormValues(autoFill);
        
        setDialogStatus(true)
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }


    return (
      <>
          {!data && <button className="btn btn-primary" onClick={addNew}> Add Address</button>}
          {data &&
            <Tooltip title="Update">
                <IconButton onClick={updateData} style={{padding:"0px"}}>
                    <Edit/>
                </IconButton>
            </Tooltip>
          }
        
            <Snackbar open={snackStatus} onClose={ ()=>{setSnackStatus(false)} } autoHideDuration={10000}  anchorOrigin={{ vertical: 'top', horizontal: 'center',  }}>
                <Alert  severity={snackType} sx={{ width: '100%' }}> {snackMessage}</Alert>
            </Snackbar>

            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >

                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Address"}</DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">
                                            
                                            <div className="col-md-12">

                                                <div className="row">
                                                    

                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Contact Person" name="contactPerson" 
                                                        className="col-md-4 col-xs-12 p-2" 
                                                    />

                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Contact Phone" name="contactPhone" 
                                                        className="col-md-4 col-xs-12 p-2" 
                                                    />

                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Contact Email" name="contactEmail" 
                                                        className="col-md-4 col-xs-12 p-2" 
                                                    />

                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="State" name="state" 
                                                        className="col-md-4 col-xs-12 p-2" 
                                                    />
                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="City" name="city" 
                                                        className="col-md-4 col-xs-12 p-2" 
                                                    />
                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="zip" name="zip" 
                                                        className="col-md-4 col-xs-12 p-2" 
                                                    />
                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Address Line 1" name="addressLine1" 
                                                        className="col-md-12 col-xs-12 p-2" 
                                                    />
                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Address Line 2" name="addressLine2" 
                                                        className="col-md-12 col-xs-12 p-2" 
                                                    />

                                                </div>

                                            </div>
                                        </div>

                                        <div className="row">
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>

                                        {savingInProgress && <div className="row"><LinearProgress/></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        
                                        
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                        
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
      </>
    )
}