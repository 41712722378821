import api from "../api/api"

const getDashBoardData = () =>{
    return new Promise((resolve, reject)=>{
        api.get("report/dashboard").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
        })
    })
}




const dashboardServices = {
    getDashBoardData,
}

export default dashboardServices;