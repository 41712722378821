import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../components/FormikControl/FormikControl';

import { Edit } from '@mui/icons-material';
import { Alert, Snackbar } from '@mui/material'
import businessServices from './businessServices';

export default function BusinessForm({userId,refreshList, masters, data=null}) {

    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);


    const [snackStatus, setSnackStatus] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackType, setSnackType] = useState("error");


    const initialValues = {
        userId: userId,
        title: "",
        businessOwner:"",
        gst:"",
        pan:"",
        businessPhone:"",
        businessEmail:"",
        businessType:"",
    }

    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true)
        businessServices.save(values).then(res =>{
            setSavingStatus(false);
            console.log(res);
            if(res.error){
                setSnackStatus(true);
                setSnackType("error");
                setSnackMessage(res.msg);
            } else {
                refreshList();
                setSnackStatus(true);
                setSnackType("success");
                setSnackMessage(res.msg);
                setDialogStatus(false);
            }
        })
    }

    const validationRule = Yup.object({
        title: Yup.string().required("Required"),
    });

    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const updateData = ()=>{

        let autoFill = {
            id:data.id,
            userId: data.userId,
            title: data.title,
            businessOwner:data.businessOwner,
            gst:data.gst,
            pan:data.pan,            
            businessPhone:data.businessPhone,
            businessEmail:data.businessEmail,
            businessType:data.businessType,
        }
        setFormValues(autoFill);
        
        setDialogStatus(true)
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }


    return (
      <>
          {!data && <button className="btn btn-primary" onClick={addNew}> Add Business</button>}
          {data && 
            <div style={{position:"absolute",right:5}}>
                <Tooltip title="Update">
                    <IconButton onClick={updateData} style={{padding:"0px"}}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
            </div>
            
          }

        
            <Snackbar open={snackStatus} onClose={ ()=>{setSnackStatus(false)} } autoHideDuration={10000}  anchorOrigin={{ vertical: 'top', horizontal: 'center',  }}>
                <Alert  severity={snackType} sx={{ width: '100%' }}> {snackMessage}</Alert>
            </Snackbar>

            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >

                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Business"}</DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">
                                            
                                            <div className="col-md-12">

                                                <div className="row">
                                                    
                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Business Name" name="title" 
                                                        className="col-md-6 col-xs-12 p-2" 
                                                    />

                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Business Owner" name="businessOwner" 
                                                        className="col-md-6 col-xs-12 p-2" 
                                                    />

                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="GST" name="gst" 
                                                        className="col-md-6 col-xs-12 p-2" 
                                                    />

                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="PAN" name="pan" 
                                                        className="col-md-6 col-xs-12 p-2" 
                                                    />

                                                    

                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Business Phone" name="businessPhone" 
                                                        className="col-md-6 col-xs-12 p-2" 
                                                    />

                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Business Email" name="businessEmail" 
                                                        className="col-md-6 col-xs-12 p-2" 
                                                    />

                                                    {masters && 
                                                        <FormikControl control="autoselect" formik={formik} 
                                                            label="Business Type" name="businessType"  
                                                            options={masters['business-type']} multiple={false} 
                                                            className="col-md-6 col-xs-6 p-2"
                                                        />
                                                    }

                                                </div>

                                            </div>
                                        </div>

                                        <div className="row">
                                        {   /*JSON.stringify(formik.values)*/}
                                        </div>
                                        {savingInProgress && <div className="row"><LinearProgress/></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        
                                        
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                        
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
      </>
    )
}