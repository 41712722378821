import { Alert, Snackbar, Switch } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import CommonLoadingSkeleton from '../../../components/CommonLoadingSkeleton';
import ExpandableCard from '../../../components/ExpandableCard'
import AppLayout from '../../../layout/components/AppLayout'
import QueueLayout from '../components/QueueLayout'
import queueServices from '../queueServices';



export default function PortalConfigPage() {

    let { id } = useParams();

    const [loading,setLoadingStatus] = useState(false);
    const [queueConfig,setQueueConfig] = useState(null);

    const [snackStatus, setSnackStatus] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackType, setSnackType] = useState("error");

    const getQueueConfig = useCallback(()=>{
        setLoadingStatus(true);
        queueServices.getQueueConfig(id).then(res=>{
            setQueueConfig(res.data);
        }).catch(error=>{
            setSnackStatus(true);
            setSnackType("error");
            setSnackMessage(error.msg);
        }).finally(()=>{
            setLoadingStatus(false);
        })

    },[id]);


    useEffect(()=>{
        getQueueConfig();
    },[getQueueConfig])
    

    return (
        <AppLayout>
            <Snackbar open={snackStatus} onClose={ ()=>{setSnackStatus(false)} } autoHideDuration={10000}  anchorOrigin={{ vertical: 'top', horizontal: 'center',  }}>
                <Alert  severity={snackType} sx={{ width: '100%' }}> {snackMessage}</Alert>
            </Snackbar>
            {loading && <CommonLoadingSkeleton/> }
            {queueConfig && queueConfig.businessHolidays &&
                <QueueLayout queueDetails={queueConfig.queueDetails}>
                    <ExpandableCard title={"Portal Config"} defaultOpen={true}>
                        <div className="card-body">
                            
                                
                            
                        </div>
                    </ExpandableCard>
            
                </QueueLayout>
            }
      </AppLayout>
    )
}

