import { Settings } from '@mui/icons-material';
import { Alert, Snackbar } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'

import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';



import { Link } from 'react-router-dom';
import CommonLoadingSkeleton from '../../../components/CommonLoadingSkeleton';
import ExpandableCard from '../../../components/ExpandableCard'
import withAlert from '../../../hoc/withAlert';
import AppLayout from '../../../layout/components/AppLayout'
import queueServices from '../queueServices'

const QueueListPage = ({AppAlert}) => {


    const [businessId,setBusinessId] = useState(1);
    const [loading,setLoadingStatus] = useState(false);
    const [queueList,setQueueList] = useState(null);

    
    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);


    const initialValues = {
        
        title:"",
        prefix:"",
        avgProcessTime:"15",
        queueId:"1"
    }



    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true)
        queueServices.clone(values).then(res =>{
            AppAlert(res.msg);
            setDialogStatus(false);
            onSubmitProps.resetForm();
            getQueueList();
        }).catch(error => {
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const validationRule = Yup.object({
        title:Yup.string().required("Required"),
        prefix:Yup.string().required("Required"),
        avgProcessTime:Yup.string().required("Required"),

    });

    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }


    



    const getQueueList = useCallback(() =>{
        setLoadingStatus(true);
        queueServices.getQueueListByBusinessId(businessId).then(res=>{
            setQueueList(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setLoadingStatus(false);
        })
    },[businessId]);

    useEffect(()=>{
        getQueueList();
    },[getQueueList])

    return (
        <AppLayout>
            
            



            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <button type="button" className="btn btn-primary float-end" onClick={addNew}>Create New</button>
                    </div>
                </div>


                <div className="row">
                    {loading && <CommonLoadingSkeleton/> }
                    {queueList && queueList.length > 0 &&
                        
                        queueList.map(queueItem=>{
                            return (
                                <div className="col-md-4 mt-3" key={queueItem.id}>
                                    <ExpandableCard title={queueItem.title} >
                                        <div className="card-body">
                                            <dl className="row">
                                                <dt className="col-sm-8">Queue #</dt>
                                                <dd className="col-sm-4">{queueItem.id}</dd>
                                                <dt className="col-sm-8">Token Prifix</dt>
                                                <dd className="col-sm-4">{queueItem.prefix}</dd>
                                                <dt className="col-sm-8">Stations</dt>
                                                <dd className="col-sm-4">{queueItem.stations}</dd>
                                                <dt className="col-sm-8">Avg Processing Time</dt>
                                                <dd className="col-sm-4">{queueItem.avgProcessTime}</dd>
                                            </dl>
                                        </div>
                                        <div className="card-footer">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Link to={"/queue/business-hours/"+queueItem.id} type="button" className="btn btn-primary float-end"><Settings/> Settings</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </ExpandableCard>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            

            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >

                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Contact"}</DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">
                                            
                                            <div className="col-md-12">

                                                <div className="row">
                                                    
                                                    
                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Queue Title" name="title" 
                                                        className="col-md-4 col-xs-12 p-2" 
                                                    />

                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Queue Prefix" name="prefix" 
                                                        className="col-md-4 col-xs-12 p-2" 
                                                    />

                                                    <FormikControl control="input" formik={formik} type="number" 
                                                        label="Avg. Process Time" name="avgProcessTime" 
                                                        className="col-md-4 col-xs-12 p-2" 
                                                    />

                                                </div>

                                            </div>
                                        </div>

                                        <div className="row">
                                        {/* JSON.stringify(formik.values) */}
                                        </div>

                                        {savingInProgress && <div className="row"><LinearProgress/></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        
                                        
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                        
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>


        </AppLayout>
    )
}


export default withAlert(QueueListPage);