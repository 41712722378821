export const ChartBackGroundColors = [
    
    "#0d6efd", //primary
    "#ffc107", //Warning
    "#198754", //Success
    "#dc3545", //Danger
    "#0dcaf0", //Info
    "#000000", //Dark
    "#6610f2", //Purple
    "#6495ED", //Indigo
    "#d63384", //Pink
    '#fd7e14',  //bs-yellow
    '#20c997',  //teal
    '#FFA07A', //LightSalmon
    '#808000',
    '#000080',
    '#FF00FF',
    '#008080',
    '#DAF7A6',
    '#9FE2BF',
    '#CCCCFF',
    '#00FF00',
    '#FFFF00',
    '#808080',
    '#C0C0C0',
    '#FFFFFF'
]