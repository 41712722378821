import React from 'react'

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { ChartBackGroundColors } from '../../services/ChartColors.';

ChartJS.register(ArcElement, Tooltip, Legend);


const  AppPieChart = ({dataLabels,chartData}) => {
    

    


    const data = {
        labels: dataLabels,
        datasets: [
          {
            data: chartData,
            backgroundColor: ChartBackGroundColors,
            borderColor: ["#FFF"],
            borderWidth: 1,
          },
        ],
    };

    const options = {
      legend: {
        display: true,
      },
      plugins: {
        legend: {
        position: "bottom",
        display: true
        },
    }
    };

    return (
        <>
            <Pie data={data}  options={options}/>
        </>
    )
}

export default AppPieChart



/*
                'rgba(128, 0, 0,1)',
                'rgba(128, 128, 0,1)',
                'rgba(255, 0, 255,1)',
                'rgba(0, 0, 128,1)',
                'rgba(0, 0, 2555,1)',
                'rgba(0, 128, 0,1)',
                'rgba(0, 255, 0,1)',
                'rgba(255, 99, 132,  1)',
                'rgba(54, 162, 235,  1)',
                'rgba(255, 206, 86,  1)',
                'rgba(75, 192, 192,  1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64,  1)',


*/