import api from "../../api/api";


const updateBusinessHours = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("queue/business-hours",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const updateBusinessBreaks = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("queue/business-breaks",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const updateHolidays = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("queue/business-holidays",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const updateAppointConfiguration = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("queue/appointment-configuration",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const updateBusinessService = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("queue/business-services",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const removeHoliday = (id) =>{
    return new Promise((resolve, reject)=>{
        api.delete("queue/business-holidays/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const removeService = (id) =>{
    return new Promise((resolve, reject)=>{
        api.delete("queue/business-services/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const removeStaff = (id) =>{
    return new Promise((resolve, reject)=>{
        api.delete("queue/business-staff/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const updateBusinessStaff = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("queue/business-staff",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const queueUpdateServices = {
    updateBusinessHours,
    updateBusinessBreaks,
    updateHolidays,
    removeHoliday,
    updateAppointConfiguration,
    updateBusinessService,
    removeService,
    updateBusinessStaff,
    removeStaff
}

export default queueUpdateServices;