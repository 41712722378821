
import React, { useEffect, useState } from 'react'
import UpdateStatusComponent from './UpdateStatusComponent'


export default function AppointmentListComponent({appointmentList, refreshList}) {
    

    
    
    return (
        <div className="row">
            <div className="col-md-12 table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Customer</th>
                            <th>Token</th>
                            <th>Queue</th>
                            <th>Service</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Date and Time</th>
                        </tr>
                    </thead>
                    <tbody>
                    {appointmentList && Array.isArray(appointmentList) && appointmentList.map(appointment =>{
                        return (
                            <tr key={appointment.id}>
                                <td>{appointment.firstName} {appointment.lastName}</td>
                                <td>{appointment.tokenPrefix}-{appointment.id}</td>
                                <td>{appointment.queueTitle}</td>
                                <td>{appointment.serviceTitle}</td>
                                <td>{appointment.appointmentDate}</td>
                                <td>{appointment.appointmentTime}</td>
                                <td>
                                    <UpdateStatusComponent appointmentId={appointment.id} appointmentTimeStamp={appointment.appointmentTimeStamp} status={appointment.status} refreshList={refreshList} />
                                </td>
                                {<td>{/*JSON.stringify(appointment)*/}</td>}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                
                
            </div>
        </div>
    )
}
