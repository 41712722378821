import api from "../../api/api";

const getQueueListByBusinessId = (businessId) => {
    return new Promise((resolve, reject)=>{
        api.get("queue/list/"+businessId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getQueueConfig = (queueId) => {
    return new Promise((resolve, reject)=>{
        api.get("queue/config/"+queueId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getSlots = (id,selectedDate) =>{
    return new Promise((resolve, reject)=>{
        //let q = "?date="+JSON.stringify(selectedDate.format("YYYY-MM-DD")).replaceAll('"', '');
        api.get("appointment/slots/"+id+"/"+selectedDate).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: "res.data.msg"
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}


const clone = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/queue/duplicate",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}



const queueServices = {

    getQueueListByBusinessId,
    getQueueConfig,
    getSlots,

    clone,
}

export default queueServices;