import React, { useCallback, useEffect, useState } from 'react'
import AppLayout from '../../../layout/components/AppLayout'
import appointmentServices from '../appointmentServices';
import AppointmentListComponent from '../components/AppointmentListComponent';

export default function AppointmentListPage() {

    const businessId = 1;

    const [appointmentList,setAppointmentList] = useState(null);

    const getList = useCallback(() => {
        appointmentServices.getAppointmentList(businessId).then(res=>{
            setAppointmentList(res.data)
        })
      },[businessId],
    )


    useEffect(()=>{
        getList();
    },[])


    return (
        <AppLayout>
            <div className="container">
                <h3>Appointment list page</h3>
                <AppointmentListComponent appointmentList={appointmentList} refreshList={getList} />
            </div>
        </AppLayout>
    )
}
