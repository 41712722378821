import { KeyboardArrowRight } from '@mui/icons-material';
import { Slide } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom';
import withAlert from '../../../hoc/withAlert';


import AppLayout from '../../../layout/components/AppLayout'
import kioskServices from '../kioskServices';



const QueueListItem = ({queue,AppAlert}) =>{

    let navigate = useNavigate();

    const [checkInStatus,setCheckInStatus] = useState(false);
    const [waitingTime,setWaitingTime] = useState(10);
    const getCheckinStatus = (queue =>{
        
        kioskServices.getCheckInStatus(queue.id).then(res=>{
            setCheckInStatus(true);
            setWaitingTime(res.waitingTime);
        }).catch(error=>{
            setCheckInStatus(false);
        })
    })

    useEffect(()=>{
        if(queue.queueType === 2){
            getCheckinStatus(queue);
        }
    },[])

    const selectQueue = () =>{
        if(queue.queueType === 2 ){
            if(checkInStatus) {
                navigate("/kiosk/queue/"+queue.id);
            } else {
                AppAlert("Sorry we are closed","error")
            }
        }

        if(queue.queueType === 1){
            navigate("/queue/"+queue.id);
        }
        
    }

    return (
        <> 
            <button type="button" onClick={selectQueue} className="list-group-item list-group-item-action d-flex justify-content-between align-items-start p-3">
                <div className="ms-2 me-auto">
                <div className="fw-bold">{queue.title}</div>
                    {queue.queueType === 2 && 
                        <>
                            { checkInStatus && 
                                <span className="text-success">
                                    Waiting Time  {waitingTime} {waitingTime > 1 ? "minutes" : "minute" }
                                </span> }
                            { !checkInStatus && <span className="text-danger">Curretly Closed</span> }
                        </>
                    }
                    {queue.queueType === 1 && <span className="text-success">Book My Appointment</span>}
                </div>
                <span className=""><KeyboardArrowRight/></span>
            </button>
        </>
    )
}


const KioskPortalPage = ({AppAlert}) => {
    let { businessId } = useParams();
    const [portalDetails,setPortalDetails] = useState(null);
    const getBusinessDetails = useCallback(()=>{
        kioskServices.getPortalDetails(businessId).then(res=>{
            setPortalDetails(res.data)
            localStorage.setItem('businessDetails',JSON.stringify(res.data.businessDetails));
        }).catch(error=>{
        })
    },[businessId]);
    useEffect(()=>{
        getBusinessDetails()
    },[])

    
    return(
        <>
            {portalDetails &&
                <>
                    <AppLayout >
                        <div className="container">
                        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                            <ol className="list-group list-group-numbered mt-2">
                                {
                                    portalDetails.queueList.map(queue =>
                                        <QueueListItem queue={queue} key={queue.id} AppAlert={AppAlert} />
                                    )
                                }
                            </ol>
                        </Slide>
                        </div>
                    </AppLayout>
                </>
            }
        </>
    )
}

export default withAlert(KioskPortalPage);