import { Alert, CircularProgress, Snackbar, Switch } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import CommonLoadingSkeleton from '../../../components/CommonLoadingSkeleton';
import ExpandableCard from '../../../components/ExpandableCard'
import AppLayout from '../../../layout/components/AppLayout'
import QueueLayout from '../components/QueueLayout'
import queueServices from '../queueServices';
import queueUpdateServices from '../queueUpdateServices';



import dayjs, { Dayjs } from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextField } from '@mui/material';




const BusinessBreaks = ({businessBreak,alertHandler}) =>{
    
    const [breakStart,setBreakStart] = useState(dayjs('2022-11-22T'+businessBreak.breakStart));
    const [breakEnd,setBreakEnd] = useState(dayjs('2022-11-22T'+businessBreak.breakEnd));
    const [breakStatus,setBreakStatus] = useState(businessBreak.breakStatus?true:false);

    const [touched,setTouched] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    /*
        let yourDate = new Date();
        yourDate = yourDate.toISOString().split('T')[0];
        const [breakStart,setBreakStart] = useState(dayjs(yourDate+businessBreaks.breakStart));
    */
    const getWeekDayName = (dayNo) =>{
        let weekDays = ["","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"]
        return weekDays[dayNo];
    }

    const handleBreakStartChange = (newValue) =>{
        console.log(newValue.format("HH:mm:ss"));
        setBreakStart(newValue);
        setTouched(true)
    }

    const handleBreakEndChange = (newValue) =>{
        console.log(newValue.format("HH:mm:ss"));
        setBreakEnd(newValue);
        setTouched(true)
    }

    const handleBreakStatusChange = (event) =>{
        setBreakStatus(event.target.checked);
        setTouched(true)
    }    

    const update = ()=>{
        setSavingStatus(true);
        let payload = {
            id:businessBreak.id,
            breakStart:breakStart.format("HH:mm:ss"),
            breakEnd:breakEnd.format("HH:mm:ss"),
            breakStatus
        }
        queueUpdateServices.updateBusinessBreaks(payload).then(res=>{
            alertHandler(res.msg);
            setTouched(false);
        }).catch(error=>{
            alertHandler(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    return (
        <tr>
            <th>{getWeekDayName(businessBreak.dayNo)}</th>
            <th><Switch checked={breakStatus} onChange={handleBreakStatusChange} name="Day Status" /></th>
            <td>
                <TimePicker
                    label="Time"
                    value={breakStart}
                    onChange={handleBreakStartChange}
                    renderInput={(params) => <TextField {...params} variant="standard" />}
                />
            </td>
            <td>
                <TimePicker
                    label="Time"
                    value={breakEnd}
                    onChange={handleBreakEndChange}
                    renderInput={(params) => <TextField {...params} variant="standard" />}
                />
            </td>
            <td>
                {touched && !savingInProgress && <button type="button" className="btn btn-success btn-sm" onClick={update}>Save</button>}
                {savingInProgress &&  <CircularProgress/> }
            </td>
        </tr>
    )
}


export default function BusinessBreaksPage() {
    
    let { id } = useParams();

    const [loading,setLoadingStatus] = useState(false);
    const [queueConfig,setQueueConfig] = useState(null);

    const [snackStatus, setSnackStatus] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackType, setSnackType] = useState("error");

    const getQueueConfig = useCallback(()=>{
        setLoadingStatus(true);
        queueServices.getQueueConfig(id).then(res=>{
            setQueueConfig(res.data);
        }).catch(error=>{
            setSnackStatus(true);
            setSnackType("error");
            setSnackMessage(error.msg);
        }).finally(()=>{
            setLoadingStatus(false);
        })

    },[id]);

    const alertHandler = (message,messageType="success") =>{
        setSnackStatus(true);
        setSnackType(messageType);
        setSnackMessage(message);
    }

    useEffect(()=>{
        getQueueConfig();
    },[getQueueConfig])
    
    return (
        <AppLayout>
            <Snackbar open={snackStatus} onClose={ ()=>{setSnackStatus(false)} } autoHideDuration={10000}  anchorOrigin={{ vertical: 'top', horizontal: 'center',  }}>
                <Alert  severity={snackType} sx={{ width: '100%' }}> {snackMessage}</Alert>
            </Snackbar>

            
                {loading && <CommonLoadingSkeleton/> }
                {queueConfig && queueConfig.businessBreaks &&
                    <QueueLayout queueDetails={queueConfig.queueDetails}>
                        <ExpandableCard title={"Business Hours"} defaultOpen={true}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12 table-responsive">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th>Start</th>
                                                    <th>End</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    
                                                    queueConfig.businessBreaks.map(businessBreak=>{
                                                        return(
                                                            <BusinessBreaks businessBreak={businessBreak} key={businessBreak.id} alertHandler={alertHandler}/>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </div>
                        </ExpandableCard>
                    </QueueLayout>
                }
            
        </AppLayout>
        )
    }
