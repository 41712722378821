
import React from 'react'
import {Input, AutoSelect, DatePicker, FormikSwitch, Radio, CheckboxInput}  from './Inputs'
import FileUploader from './FileUploader'
import SearchSelect from './SearchSelect'

function FormikControl(props) {
  const {control, ...rest} = props
  switch(control){
    case 'input':
          return <Input {...rest} />

    case 'autoselect':
          return <AutoSelect {...rest} />

    case 'searchselect':
      return <SearchSelect {...rest} />

    case 'date':
      return <DatePicker {...rest} />

    case 'switch':
      return <FormikSwitch {...rest} />
    case 'radio':
        return <Radio {...rest} />
    case 'checkbox':
        return <CheckboxInput {...rest} />
    case 'file':
        return <FileUploader {...rest} />
    default: return null

  }
}

export default FormikControl