import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { startSession } from "./ams/redux/authStore";
import AppRoutes from "./ams/routes/AppRoutes";

function App() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  let location = useLocation();
  const authUser = useSelector(state => state.auth);

  const userId = authUser.id;

  useEffect(()=>{ 
    if(localStorage.authUser){
      userId === undefined && dispatch(startSession())
    } else {
      
      console.log("location",location)
      if(location.pathname != "/auth/register"){
        navigate("/auth/login");
      }
      
    }
  },[dispatch,navigate,userId])



  return (
    <div className="App">
      <AppRoutes/>
    </div>
  );
}

export default App;
