import api from "../api/api";

export default class MasterServices  {
    static get = (masters) => {
        return new Promise((resolve, reject)=>{
            api.get('masters/dd/'+masters.join()).then((res) => {
                //console.log(res.data);
                let allMasters = res.data;
                let data = {};
                for (const master in allMasters) {
                    if(!allMasters[master].error){
                        data[master] = allMasters[master].data;
                    }
                }
                resolve(data);

            }).catch(error =>{
                reject("Something went wrong while loading masters");
            })
        })
    }
}